import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RevenueTrendStandings.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';

const RevenueTrendStandings = ({ standingData }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(''); // Default to "All"
  const [selectedCity, setSelectedCity] = useState(''); // Default to "All"
  const [loading, setLoading] = useState(false); // Loader state

  const handleSelectedPeriodChange = useCallback((event) => {
    setSelectedTimePeriod(event?.target?.value);
  }, []);

  const handleSelectedCityChange = useCallback((event) => {
    setSelectedCity(event?.target?.value);
  }, []);

  const fetchRevenueTrendChart = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        month: selectedTimePeriod,
        city:selectedCity
      };

      const response = await axios.post(
        'https://ritaprodapi.project-lithium.com/noc-service/lithium-super-city-league/revenue/trend',
        payload,
        {
          headers: {},
        }
      );
      
      if (response?.data?.data?.length >0) {
        setChartData(response.data.data);
      } else {
        setChartData([]); // Set empty array if no data
      }
    } catch (error) {
      setChartData([]); 
      console.error('Error fetching revenue trend chart data:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  }, [selectedTimePeriod, selectedCity]);

  useEffect(() => {
    fetchRevenueTrendChart();
  }, [fetchRevenueTrendChart]);

  // Prepare data for ApexCharts
  const chartOptions = useMemo(() => {
    const categories =
      selectedTimePeriod === ''
        ? (chartData || []).map((item) => item?.date || '') // Ensure it’s always an array
        : (chartData || []).map((item) => {
            if (!item?.Date) return ''; // Prevent errors if Date is missing
            const [day, month, year] = item?.Date?.split('-');
            return new Date(`${year}-${month}-${day}`)?.toISOString();
          }).filter(Boolean); // Removes empty values
  
    const series =
      (chartData || []).length > 0
        ? selectedTimePeriod === 'Feb-25'
          ? [
              {
                name: 'Feb Actual Revenue',
                data: chartData.map((item) => item['feb-25-actual-revenue'] || null), // Provide a default 0
              },
              {
                name: 'Feb Pan Revenue',
                data: chartData.map((item) => item['feb-25-plan-revenue'] || null), // Provide a default 0
              },
              
            ]
          : [
            {
              name: 'Jan Actual Revenue',
              data: chartData.map((item) => item['jan-25-actual-revenue'] ?? null),
            },
            {
              name: 'Plan Revenue',
              data: chartData.map((item) => item['jan-25-plan-revenue'] ?? null),
            },
            {
              name: 'Feb Actual Revenue',
              data: chartData.map((item) => item['feb-25-actual-revenue'] || null), // Provide a default 0
            },
            ]
        : [];
  
    return {
      chart: {
        type: 'line',
        height: 350,
        offsetX: 20,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
        },
      },
      xaxis: {
        type: selectedTimePeriod === '' ? 'category' : 'category',
        categories: categories,
        tickAmount: 20, // Adjust tickAmount according to your requirement
      },
      series: series.length > 0 ? series : [{ name: 'No Data', data: [] }], // Prevent empty series error
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          if (!w.config.series[seriesIndex]?.data?.length) return '';
        
          const lastIndex = w.config.series[seriesIndex].data.length - 1 && w.config.series[seriesIndex]?.data.filter(value => value !== null).length-1;
          // Show the value only if it's the last point of the current line
          if (dataPointIndex !== lastIndex) return '';
          // Format the value for readability
          if (val >= 10000000) return `${(val / 10000000).toFixed(2)} Cr`;
          if (val >= 100000) return `${(val / 100000).toFixed(2)} Lakh`;
          return `${val?.toLocaleString('en-IN')}`;
        },
        style: {
          fontSize: '12px',
          colors: ['#606060'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: 'Revenue Growth Chart',
        align: 'left',
        fontSize:"20px"
      },
      grid: {
        padding: {
          right: 40,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
    };
  }, [chartData, selectedTimePeriod]);
  

  return (
    <div className="city-league-sites-container-sp">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className="revenue-trending-chart-grid-container">
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image-trend" />
            </div>
          </Grid>
          <Grid item xs={11} sm={9}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div className="city-league-sites-title-sp">REVENUE GROWTH CHART</div>
              <div>
                <select
                  name="Period"
                  id="time_period"
                  className="client-sites-container-select stylish-select-long"
                  onChange={handleSelectedCityChange}
                  value={selectedCity}
                >
                  <option value="">All Cities</option>
                  <option value="BLR">BLR</option>
                  <option value="HYD">HYD</option>
                  <option value="NCR">NCR</option>
                  <option value="MUM">MUM</option>
                  <option value="PNQ">PNQ</option>
                  <option value="CHN">CHN</option>
                  <option value="KOL">KOL</option>
                  <option value="Uber">UBER</option>
                </select>
              </div>
              <div>
                <select
                  name="Period"
                  id="time_period"
                  className="client-sites-container-select stylish-select-long"
                  onChange={handleSelectedPeriodChange}
                  value={selectedTimePeriod}
                >
                  <option value="">All Month</option>
                  <option value="Jan-25">Jan-25</option>
                  <option value="Feb-25">Feb-25</option>
                  <option value="Mar-25">Mar-25</option>
                </select>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <div style={{ minHeight: '480px', background: 'white', padding: '20px', borderRadius: '10px' }}>
        {loading ? (
          <CircularProgress /> // Show loader while fetching data
        ) : chartData.length > 0 ? (
          <Chart options={chartOptions} series={chartOptions.series} type="line" height={450} />
        ) : (
          <p style={{ color: '#888', fontSize: '16px', textAlign: 'center' }}>No data available</p> // Show blank chart message
        )}
      </div>
    </div>
  );
};

export default RevenueTrendStandings;