import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
// import ClientSites from '../client-sites/ClientSites';
import CityLeagueStandings from '../city-league-standings/CityLeagueStandings';
import './LithiumCityLeagueTabs.css';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import SpContestStandings from '../sp-contest/SpContestStandings';
import SiteTeamContestStandings from '../site-contest/SiteTeamContestStandings';
import UberContest from '../uber-contest/UberContest';
import RandMContestStandings from '../R&M-contest/RandMContestStandings';
import SpHiringContestStandings from '../SP-hiring-contest/SpHiringContestStandings';
import SpEnagementContestStandings from '../SP-engagement-team-contest/SpEnagementContestStandings';
import SuperCityContestStandings from '../super-city/SuperCityContestStandings';
import backgroundImage from './Scoard_Bgm.png';
import RevenueTrendStandings from '../revenue-trend/RevenueTrendStandings';

ReactGA.initialize('G-1GBL3E4J72');

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className='simple-tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LithiumCityLeagueTabs = () => {
    const [value, setValue] = useState(0);
    const [standingData, setStandingData] = useState({ standingData: [], loading: false, error: null });
    const [superCityRunRateData, setSuperCityRunRateData] = useState({ run_rate_data: [], loading: false, error: null });
    const [spContestData, setSpContestData] = useState({ spData: [], loading: false, error: null });
    const [siteContestData, setSiteContestData] = useState({ site_team_data: [], loading: false, error: null });
    const [spHiringContestData, setSpHiringContestData] = useState({ sp_hiring_data: [], loading: false, error: null });
    const [spEngagementContestData, setEngagementContestData] = useState({ sp_engagement_data: [], loading: false, error: null });
    const [rmContestData, setRmContestData] = useState({ rm_team_data: [], loading: false, error: null });
    const [uberContestData, setUberContestData] = useState({ uber_team_data: [], loading: false, error: null });
    
    // const [leadingClientsData, setLeadingClientsData] = useState({ leading_clients_data: [], loading: false, error: null });
    const [color, setColor] = useState('purple');

  

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const path = newValue === 0 ? '/city-lead-standing' : '/leading-client-sites';
        ReactGA.pageview(path);
    };
    
    const fetchCityLeagueStandings = useCallback(async () => {
        try {
            setStandingData(prev => ({ ...prev, loading: true }));
            setSpContestData(prev => ({ ...prev, loading: true }));
            setSiteContestData(prev => ({ ...prev, loading: true }));
            setSuperCityRunRateData(prev => ({ ...prev, loading: true }));
            setSpHiringContestData(prev => ({ ...prev, loading: true }));
            setEngagementContestData(prev => ({ ...prev, loading: true }));
            setRmContestData(prev => ({ ...prev, loading: true }));
            setUberContestData(prev => ({ ...prev, loading: true }));
            const response = await axios.get('https://ritaprodapi.project-lithium.com/noc-service/super-city-league-contest');
            if (response?.data) {
                setSpContestData({ spData: response.data.sp_contest_data, loading: false, error: null })
                setSiteContestData({ site_team_data: response.data.site_team_data, loading: false, error: null })
                setStandingData({ standingData: response.data.standing_data, loading: false, error: null });
                setSuperCityRunRateData({ run_rate_data: response.data.run_rate_data, loading: false, error: null });
                setSpHiringContestData({ sp_hiring_data: response.data.sp_hiring_data, loading: false, error: null });
                setEngagementContestData({ sp_engagement_data: response.data.sp_engagement_data, loading: false, error: null });
                // setLeadingClientsData({ leading_clients_data: response.data.leading_clients_data, loading: false, error: null });
                setRmContestData({ rm_team_data: response.data.rm_team_data, loading: false, error: null });
                setUberContestData({ uber_team_data: response.data.uber_team_data, loading: false, error: null });
            }

        } catch (error) {
            console.error('Error fetching standings:', error);
            setStandingData({ standingData: [], loading: false, error });
        }
    }, []);

    useEffect(() => {
        fetchCityLeagueStandings();
        setColor('purple');
    }, [fetchCityLeagueStandings]);

    const gridContainerStyle = {
        backgroundImage: value === 0 ? `url(${backgroundImage})` : null,
        backgroundSize: 'contain', // Adjust to fit the container
       };
       const gridContainernewStyle = {
       };

    return (
        <div>
            <Card className='lithium-city-league-container' >
                <Box className='lithium-city-league-box-container' style={value === 0 ? gridContainerStyle: gridContainernewStyle} sx={{ width: '100%', background: value === 1 ? 'black' : value === 2 ? color: value === 3 ?  'slateblue' : value === 4? "steelblue" : value === 5 ? "tomato" : value === 6 ? "teal": value === 7 ? "crimson" : value === 8 ? "#00bca0" :color }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "5px 20px" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            className='lithium-city-tab'
                            aria-label="basic tabs example"
                            centered
                        >
                            <Tab
                                label="Super City"
                                {...a11yProps(0)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontSize: '12px',
                                        padding:"0px",
                                        fontWeight: "700",
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                        fontWeight: "600"
                                    },
                                }}
                            />
                            <Tab
                                label="City Contest"
                                {...a11yProps(0)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontSize: '12px',
                                        padding:"0px",
                                        fontWeight: "700",
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                        fontWeight: "600"
                                    },
                                }}
                            />
                            <Tab
                                label="SP CONTEST"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="SITE CONTEST"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="UBER CONTEST"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="R&M CONTEST"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="SP HIRING"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="SP ENGAGEMENT"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                            <Tab
                                label="REVENUE TREND"
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <SuperCityContestStandings standingData={superCityRunRateData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <CityLeagueStandings standingData={standingData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <SpContestStandings standingData={spContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <SiteTeamContestStandings standingData={siteContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <UberContest standingData={uberContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <RandMContestStandings standingData={rmContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={6}>
                        <SpHiringContestStandings standingData={spHiringContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={7}>
                        <SpEnagementContestStandings standingData={spEngagementContestData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={8}>
                        <RevenueTrendStandings standingData={spEngagementContestData} />
                    </CustomTabPanel>
                </Box>
            </Card>
        </div>
    );
}

export default LithiumCityLeagueTabs;
