import React, { useState } from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import "./SafetySuggestions.css";

const SafetySuggestionForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    lithium_id: "",
    mobile_number: "",
    suggestion: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    lithium_id: false,
    mobile_number: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate input based on the field name
    switch (name) {
      case "name":
        // Allow only alphabets and spaces
        if (/^[A-Za-z\s]*$/.test(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
          }));
        }
        break;

      case "lithium_id":
        // Allow only alphanumeric characters
        if (/^[A-Za-z0-9]*$/.test(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
          }));
        }
        break;
      
      case "suggestion":
        // Allow only alphabets and spaces
        if (/^[A-Za-z\s]*$/.test(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
          }));
        }
        break;

      case "mobile_number":
        // Allow only numbers and limit to 10 digits
        if (/^\d{0,10}$/.test(value)) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
          }));
        }
        break;

      default:
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        break;
    }
  };

  const handleSubmit = async () => {
    // Check if the suggestion field is empty
    if (!formData.suggestion.trim()) {
      alert("Please fill out the safety suggestion before submitting.");
      return;
    }

    // Validate all fields before submission
    const newErrors = {
      name: !/^[A-Za-z\s]*$/.test(formData.name),
      lithium_id: !/^[A-Za-z0-9]*$/.test(formData.lithium_id),
      mobile_number: !/^\d{10}$/.test(formData.mobile_number),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      alert("Please correct the errors in the form before submitting.");
      return;
    }

    const apiUrl =
      "https://ritaprodapi.project-lithium.com/noc-service/api/safety-suggestion/demo";
    const payload = {
      name: formData.name,
      lithium_id: formData.lithium_id,
      mobile_number: formData.mobile_number,
      suggestion: formData.suggestion,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Suggestion submitted successfully!");
        setFormData({
          name: "",
          lithium_id: "",
          mobile_number: "",
          suggestion: "",
        });
      } else {
        alert("Failed to submit suggestion. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting suggestion:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="SafetySuggestionForm-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow:
            "0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)",
          borderRadius: "5px",
          bgcolor: "#a3e6db",
          padding: 2,
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
          Your suggestion on safety would help us to improve
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "center", marginBottom: 2 }}
        >
          To keep feedback anonymous, do not share name and mobile
        </Typography>
        <TextField
          label="Name (Optional)"
          name="name"
          variant="outlined"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 2, maxWidth: 400, background: "white" }}
          error={errors.name}
          helperText={errors.name ? "Only alphabets and spaces are allowed." : ""}
        />
        <TextField
          label="Lithium Id (Optional)"
          name="lithium_id"
          variant="outlined"
          value={formData.lithium_id}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 2, maxWidth: 400, background: "white" }}
          error={errors.lithium_id}
          helperText={errors.lithium_id ? "Only alphanumeric characters are allowed." : ""}
        />
        <TextField
          label="Mobile (Optional)"
          name="mobile_number"
          variant="outlined"
          value={formData.mobile_number}
          onChange={handleChange}
          fullWidth
          sx={{ marginBottom: 2, maxWidth: 400, background: "white" }}
          error={errors.mobile_number}
          helperText={errors.mobile_number ? "Please enter a valid 10-digit mobile number." : ""}
        />
        <TextField
          label="Safety Suggestion"
          name="suggestion"
          variant="outlined"
          value={formData.suggestion}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: 2, maxWidth: 400, background: "white" }}
          required
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            bgcolor: "red",
            color: "white",
            padding: "10px 30px",
            "&:hover": { bgcolor: "darkred" },
          }}
        >
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default SafetySuggestionForm;